export default defineNuxtRouteMiddleware(async () => {
  const user = useSupabaseUser()
  
  if (!user.value) {
    return navigateTo('/login')
  }

  try {
    const { data: userInfos } = await useUserInfos()
    
    if (!userInfos.value || userInfos.value.user_role !== 'admin') {
      return navigateTo('/')
    }
  } catch (error) {
    return navigateTo('/login')
  }
}) 